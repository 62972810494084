<template>
    <div id="box">
           <div class="" >
                <Row type="flex" justify="space-around" class="code-row-bg">
                    <Col span="6" push="3" class-name="zixingg" @click.native="shouye()">首页</Col>
                    <Col span="6"  class-name="zixing">>信息公开</Col>
                    <Col span="6"  class-name="zixing">>联系我们</Col>
                    <Col span="6" class-name="zi">>咨询留言</Col>
              </Row>
            
           </div>
          <div class="jigoubox" >
                <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                <div class="ziti">咨询留言</div>
                <div class="hengxian"></div>
            </div>
            <div class="zhuzen">
                 <div class="neirong">
                <div class="biaoti">
                   <div  class="biao"> 标题:</div> <Input v-model="value1" size="large" placeholder="请输入标题信息" class="value1" />
                </div>
                <div class="cont">
                    <el-input type="textarea"  rows="15"  class="textarea" placeholder="请输入文字，字数上线为300字" v-model="textarea" maxlength="300" show-word-limit></el-input>
                   <Button type="primary" class="btn" @click="tijiao()">提交</Button>
                 </div>
                 
              </div>
                <div class="ziwent">
                    <div class="ti">
                        <div class="tu">
                            <img class="kefu" src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1622600307219.png" alt="">
                            <div class="cj">常见问题</div>
                        </div>
                        <div class="liebia" v-for="(item,index) in liebia" :key="index" >
                            <template v-if="item.isCommon==1">
                                <img  class="wenti1" src="../../../assets/img/xinx/问题@2x.png" alt="">
                                <div class="wentii" @click="clkLiebia(item)" >{{item.questionName}}</div> 
                            </template>
                                
                        </div>
                        
                    </div>
                     <Modal  v-model="modal10">
                        <div class="title" slot="header">
                            <div class="trr">
                                <img src="../../../assets/img/xinx/Combined Shape.png"  class="tuq" alt="">
                                <div class="wentt">问题:</div>
                                <div class="wenzit">{{rowData.questionName}}</div>
                            </div>
                        </div>
                        <div class="huifu">
                                <img  class="wenti" src="https://medoss.oss-cn-shanghai.aliyuncs.com/uploads/1622599242827.png" alt="">
                                <div class="hh">回复:</div>
                                <div class="wenzi">{{rowData.answerContent}}</div>
                        </div>
                        <div slot="footer" class="footerBox">
                           <Button type="primary" @click="modal10=false">返回</Button> 
                        </div>
                     </Modal>
                </div>
                 
            </div>
           
        </div>
</template>
<script>
import {queryQuestion,saveLeaveMsg} from "../../../woke/xinx"
export default {
    data(){
        return{
            value1:"",
            textarea:"",
            liebia:[],
            modal10: false,
            loading: true,
           rowData:{},
            questionName:"",
         

        }
    },
    created(){
        this.queryQuestion()
    },
    methods:{
        shouye(){
            this.$router.push("/index")
        },
        //查询常见问题
        queryQuestion(){
            queryQuestion({
                isCommon:this.isCommon
            }).then(res=>{
                console.log(res,"查询常见问题");
                this.liebia = res.data.data
                this.liebia.forEach((item,index)=>{
                    if(this.liebia[index].isCommon==2){
                        this.liebia.splice(index,1)
                    }
                })
            })
        },
        //用户留言
        tijiao(){
            if(this.value1==""){
                     this.$Message.warning('请先输入标题');
                 }else if(this.textarea==""){
                     this.$Message.warning('请先输入内容');
                 } else{
                     saveLeaveMsg({
                        title:this.value1,
                        content:this.textarea
                    }).then(res=>{
                        //  console.log(res);
                        if(res.data.code == 200){
                            this.$Message.success('留言成功');
                        }
                    this.value1=""
                    this.textarea=""
                    })
                 }
           
        },
        clkLiebia(row){
            this.rowData = row;
            this.modal10 = true;
        },
       
    }
}
</script>
<style lang="less" scoped>
.cj{
    margin-left: 5px;
}
#box{
    margin-left: 100px;
}
.code-row-bg{
    width: 350px;
    margin-left: 240px;
    margin-top: 60px;
}
.zixingg{
     margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    margin-left: -20px;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
    margin-left: -20px;
}
.jigoubox{
    width: 100px;
    margin-left: 295px;
    margin-top: 30px;
  
}
.hengxian{
     margin-top: 20px;
     width:1130px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
    /* margin-left: 100px; */
}
.ziti{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2C3B5B;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}
.biaoti{
    width: 700px;
    height: 84px;
    background: #F8FBFF;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;

}
.biao{
    font-size: 24px;
font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
font-weight: bold;
color: #2C3B5B;
line-height: 31px;
letter-spacing: 2px;

}
.value1{
  width: 600px;
}
.cont{
width: 700px;
height: 520px;
background: #F8FBFF;

}
.textarea{
     width: 680px;
     height: 300px;
     margin-left: 10px;
}
.btn{
    width: 180px;
    height: 50px;
    margin-top: 81px;
    margin-left: 10px;
    // margin-bottom: 100px;

}
.neirong{
    width: 704px;
    height: 600px;
     margin-top: 20px;
    margin-left: 320px;
    margin-bottom: 100px;
}
.zhuzen{
    width: 1400px;
   
    display: flex;
    justify-content: space-around;
}
.ziwent{
    margin-top: 20px;
    width: 400px;
     height: 600px;
    margin-left: 30px;
    text-align: center;
    background: #F8FBFFFF;
    /* padding: 20px; */
}
.ti{
     width:400px;
    height: 40px;
      background: #005CB0;
}
.tu{
    width:400px;
    height: 40px;
    display: flex;
    justify-content: center;
  
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
    letter-spacing: 1px;
}
.wenti{
    width: 14px;
    height: 16px;
    margin-top: 5px;
}
.wenti{
    width: 14px;
    height: 16px;
}
.wentii{
    width: 350px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: -3px;
    margin-left: 5px;
}
.liebia{
    width: 400px;
    display: flex;
    justify-content: left;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2C3B5B;
    line-height: 21px;
    letter-spacing: 1px;
    margin-left: 20px;
    margin-top: 20px;
    cursor: pointer;
}
.huifu{
    display: flex;
    justify-content: left;
    /* margin-left: 10px; */
}
.hh{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #4D4D4D;
    margin-left: 5px;
}
.conter{
    width: 950px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-left: -10px;
   
}
/deep/ .ivu-btn-text{
    display: none;
}
.title{
    width: 1000px;
}
.wenzi{
    width: 900px;
    margin-left: 39px; 
}
.tuq{
    width: 10px;
    height: 16px;
    margin-top: 4px;
    
}
.wenzit{
    width: 800px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #1A1A1A;
    line-height: 24px;
    letter-spacing: 2px;
    margin-left: 39px;
}
.wentt{
    width: 50px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #4D4D4D;
    line-height: 24px;
    margin-left: 6px;
}
.wentq{
    width: 800px;
    
}
.trr{
    width: 800px;
    display: flex;
    justify-content: left;
}
/deep/ .ivu-modal-content{
    width: 1100px;
    margin-left: -290px;
}
.tibiao{
    width: 10px;
    height: 16px;
    margin-top: -3px;
}
.footerBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.kefu{
    width: 16px;
    height: 18px;
    margin-top: 11px;
}
</style>